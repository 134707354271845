import PropTypes from 'prop-types';

const Wrapper = ({ children, className = '' }) => {
    return (
        <div className={`max-w-screen-xl mx-auto ${className}`}>{children}</div>
    );
};

Wrapper.propTypes = {
    className: PropTypes.string,
};

export default Wrapper;

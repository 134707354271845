import { useState, useRef, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { FiPaperclip } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa';

const FileInput = ({
    label,
    description = '',
    info = '',
    className = '',
    showPreview = false,
    ...props
}) => {
    const input = useRef();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    const { value, ...rest } = field;
    const [preview, setPreview] = useState(null);

    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (value && showPreview) {
            setPreview(URL.createObjectURL(value?.[0]));
        }
    }, [value, showPreview]);

    useEffect(() => {
        if (!field.value) setPreview(null);
    }, [field]);

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}
            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <div className="relative group/file-input">
                <button
                    type="button"
                    className="text-primary-500 group-hover/file-input:text-primary-600 cursor-pointer transition-colors text-base inline-flex items-center"
                    style={{
                        display: showPreview && value ? 'none' : 'flex',
                    }}
                >
                    <FiPaperclip className="stroke-primary-500 group-hover/file-input:stroke-primary-600 transition-colors mr-2 min-w-[1rem]" />
                    <span className="block overflow-hidden max-w-[200px]">
                        {fileName ? fileName : 'Select file'}
                    </span>
                    {fileName ? (
                        <span
                            type="button"
                            className="z-10 p-1 ml-2 cursor-pointer"
                            onClick={() => {
                                input.current.value = '';
                                setFieldValue(field.name, null);
                                setFileName('');
                            }}
                        >
                            <FaTimes className="fill-primary-500 hover:fill-primary-600 w-3 h-3" />
                        </span>
                    ) : null}
                </button>
                <input
                    type="file"
                    {...rest}
                    {...props}
                    className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                    ref={input}
                    onChange={event => {
                        setFieldValue(field.name, event.currentTarget.files);
                        if (
                            event.currentTarget?.files &&
                            event.currentTarget.files.length
                        ) {
                            console.log(
                                event.currentTarget.value.split('\\').pop()
                            );
                            setFileName(
                                event.currentTarget.value.split('\\').pop()
                            );
                            if (showPreview)
                                setPreview(
                                    URL.createObjectURL(
                                        new Blob(
                                            [event.currentTarget.files[0]],
                                            {
                                                type: event.currentTarget
                                                    .files[0].type,
                                            }
                                        )
                                    )
                                );
                        }
                    }}
                />
            </div>

            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
            {showPreview && preview ? (
                <div className="relative">
                    <img
                        src={preview}
                        alt="Upload preview"
                        className="h-auto max-h-[150px] max-w-[150px] w-auto"
                    />
                    <button
                        onClick={() => {
                            input.current.value = '';
                            setFieldValue(field.name, null);
                            setPreview(null);
                            setFileName('');
                        }}
                        className="bg-white rounded-full cursor-pointer inline-block p-1 absolute right-3 -top-3 transition-shadow shadow-md"
                    >
                        <FaTimes />
                    </button>
                </div>
            ) : null}
        </InputContainer>
    );
};

export default FileInput;

import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { inputClasses } from '../../inc/utils';
import { useEffect, useRef, useState } from 'react';
import useQueryParams from '../../hooks/useQueryParams';

const SelectInput = ({
    label,
    description = '',
    info = '',
    className = '',
    syncQuery = false,
    onQuerySync = null,
    ...props
}) => {
    const { getQueryParam, setQueryParams } = useQueryParams();
    const [field, meta] = useField(props);
    const [prevValue, setPrevValue] = useState(field.value);
    const { setFieldValue } = useFormikContext();
    const defaultValueSet = useRef(false);

    // Set initial value from query
    useEffect(() => {
        if (defaultValueSet.current) return;
        defaultValueSet.current = true;

        if (syncQuery === true) {
            const value = getQueryParam(props.name);
            if (value) {
                setFieldValue(props.name, value);
                if (onQuerySync) {
                    onQuerySync(value);
                }
            }
        }
    }, [
        props.name,
        field,
        syncQuery,
        setFieldValue,
        getQueryParam,
        onQuerySync,
    ]);

    // Sync query
    useEffect(() => {
        if (prevValue !== field.value) {
            setPrevValue(field.value);

            if (syncQuery === true) {
                setQueryParams({ [props.name]: field.value });
            }
        }
    }, [field.value, props.name, syncQuery, prevValue, setQueryParams]);

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <select {...field} {...props} className={`${inputClasses}`} />
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default SelectInput;

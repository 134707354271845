import {
    Squares2X2Icon,
    ClipboardDocumentListIcon,
    UserGroupIcon,
    IdentificationIcon,
    RectangleStackIcon,
    Cog8ToothIcon,
    HomeIcon,
    UsersIcon,
    InboxArrowDownIcon,
} from '@heroicons/react/24/outline';

const managerNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: Squares2X2Icon,
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: ClipboardDocumentListIcon,
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: UserGroupIcon,
    },
    { title: 'Clients', href: 'd/clients', icon: UsersIcon },
    { title: 'Export', href: 'd/export', icon: InboxArrowDownIcon },
];

export default managerNav;

import { useEffect } from "react";
import { useAuthStatus } from "../../context/AuthContext";

const HubSpotWrapper = (props) => {
  const { claims } = useAuthStatus();

  const isProduction = process.env.REACT_APP_DEPLOY_ENV === "production";

  useEffect(() => {
    if (isProduction && claims?.role === "client") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.async = true;
      script.defer = true;
      script.src = "//js.hs-scripts.com/46060550.js";
      document.head.appendChild(script);
      return () => {
        script.remove();
      };
    } else {
      const scriptElement = document.getElementById("hs-script-loader");
      if (scriptElement) {
        scriptElement.remove();
      }
    }
  }, [isProduction, claims]);
  return <>{props.children}</>;
};

export default HubSpotWrapper;

import { useState, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { scrollbarClasses } from '../../inc/utils';
import { BiSearch } from 'react-icons/bi';

function SuperSelectInputInner({
    label,
    description = '',
    info = '',
    className = '',
    options,
    props,
}) {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const pickedOptionsContainer = useRef();

    // const options = props?.children?.map(option => {
    //     const value = option.props.value;
    //     const label = option.props.children;
    //     return { value, label };
    // });

    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [pickedOptions, setPickedOptions] = useState([]);
    const [searchText, setSearchText] = useState('');

    // Set initial picked options
    useEffect(() => {
        if (field.value && field.value.length) {
            const pickedOptions = options.filter(option =>
                field.value.includes(option.value)
            );
            setPickedOptions(pickedOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set form value on picked options list change
    useEffect(() => {
        setFieldValue(
            field.name,
            pickedOptions.map(option => option.value)
        );
        // Scroll to the bottom of the container
        pickedOptionsContainer.current.scrollTop =
            pickedOptionsContainer.current.scrollHeight;
    }, [pickedOptions, setFieldValue, field.name]);

    // Change displayed options on search and on picked change
    useEffect(() => {
        options &&
            options.length &&
            setDisplayedOptions(
                options.filter(
                    option =>
                        option.label
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) &&
                        !pickedOptions.map(o => o.value).includes(option.value)
                )
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, pickedOptions, options]);

    // Add option
    const addOption = option => {
        setPickedOptions(prev => [...prev, option]);
    };

    // Remove option
    const removeOption = option => {
        setPickedOptions(prev => prev.filter(o => o.value !== option.value));
    };

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
                <div className="flex flex-col border border-solid border-light-gray rounded-sm p-1 w-full">
                    <div className="p-2">
                        <div className="flex items-center border border-solid border-light-gray bg-white">
                            <BiSearch className="fill-dark-gray w-4 h-4 m-2" />
                            <input
                                type="text"
                                placeholder="Type to search"
                                className="w-full border-none outline-none focus:ring-0 p-0"
                                value={searchText}
                                onChange={e =>
                                    setSearchText(e.currentTarget.value)
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={`max-h-40 h-40 overflow-y-auto ${scrollbarClasses}`}
                    >
                        {displayedOptions && displayedOptions.length
                            ? displayedOptions.map(option => (
                                  <div
                                      key={option.value}
                                      className="text-sm text-gray-800 py-1 px-2 cursor-pointer bg-transparent hover:bg-primary-100 transition"
                                      onClick={() => addOption(option)}
                                  >
                                      {option.label}
                                  </div>
                              ))
                            : null}
                    </div>
                </div>
                <div className="flex border border-solid border-light-gray rounded-sm p-3 w-full">
                    <div
                        className={`max-h-40 h-40 overflow-y-auto ${scrollbarClasses}`}
                        ref={pickedOptionsContainer}
                    >
                        {pickedOptions && pickedOptions.length ? (
                            pickedOptions.map(option => (
                                <div
                                    key={option.value}
                                    className="inline-flex relative pt-0 pr-3 pb-3 pl-0"
                                >
                                    <span className="inline-flex items-center text-white bg-primary-500 py-1 pl-3 pr-2 text-sm rounded-tl-full rounded-bl-full">
                                        {option.label}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={() => removeOption(option)}
                                        className="rounded-none py-1 pl-2 pr-3 text-white bg-primary-500 hover:bg-red-700 cursor-pointer rounded-tr-full rounded-br-full transition"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="text-sm text-dark-gray">
                                Nothing here yet
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
}

function SuperSelectInput({
    label,
    description = '',
    info = '',
    className = '',
    ...props
}) {
    const options = props?.children?.map(option => {
        const value = option.props.value;
        const label = option.props.children;
        return { value, label };
    });
    return (
        <SuperSelectInputInner
            label={label}
            description={description}
            info={info}
            className={className}
            options={options}
            props={props}
        />
    );
}

export default SuperSelectInput;

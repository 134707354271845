import { useState } from 'react';
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';

const useStoreFile = () => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [pause, setPause] = useState(null);
    const [resume, setResume] = useState(null);
    const [cancel, setCancel] = useState(null);

    const storage = getStorage();

    const _uploadFile = async (refStr, image) => {
        return new Promise((resolve, reject) => {
            try {
                const fileRef = ref(storage, refStr);
                const uploadTask = uploadBytesResumable(fileRef, image);

                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                                100
                        );
                        console.log(progress);
                        setProgress(progress);
                        // switch (snapshot.state) {
                        //     case 'paused':
                        //         console.log('Upload is paused');
                        //         break;
                        //     case 'running':
                        //         console.log('Upload is running');
                        //         break;
                        //     default:
                        //         break;
                        // }
                    },
                    error => {
                        switch (error.code) {
                            case 'storage/unauthorized':
                                reject(
                                    'You do not have permission to access the object'
                                );
                                break;
                            case 'storage/cancelled':
                                reject('Upload cancelled');
                                break;
                            case 'storage/unknown':
                                reject('Error uploading the file');
                                break;
                            default:
                                reject('Error uploading the file');
                                break;
                        }
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(
                            downloadURL => {
                                resolve(downloadURL);
                            }
                        );
                        // updateMetadata(fileRef, { customMetadata: metadata })
                        //     .then(metadata => {
                        //         getDownloadURL(uploadTask.snapshot.ref).then(
                        //             downloadURL => {
                        //                 resolve(downloadURL);
                        //             }
                        //         );
                        //     })
                        //     .catch(error =>
                        //         reject('Error updating metadata: ' + error)
                        //     );
                    }
                );
                setPause(() => uploadTask.pause);
                setResume(() => uploadTask.resume);
                setCancel(() => uploadTask.cancel);
            } catch (error) {
                reject(error);
            }
        });
    };

    const deleteFile = async refStr => {
        const storage = getStorage();
        const fileRef = ref(storage, refStr);
        await deleteObject(fileRef);
    };

    const uploadFile = async (refStr, image) => {
        setUploading(true);
        const downloadURL = await _uploadFile(refStr, image);
        setUploading(false);
        return downloadURL;
    };

    return {
        uploadFile,
        deleteFile,
        uploading,
        progress,
        pause,
        resume,
        cancel,
    };
};

export default useStoreFile;

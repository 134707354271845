import { useRef, useEffect, useState } from 'react';
import { useAuthStatus } from '../../../context/AuthContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import SlideOut, {
    SlideOutContent,
    SlideOutHeader,
} from '../../../core-ui/SlideOut/SlideOut';

function BookCallForm() {
    const script = useRef(null);
    const { claims } = useAuthStatus();
    const [currentUser, setCurrentUser] = useState(null);

    //get user data
    useEffect(() => {
        const getUser = async () => {
            try {
                const userDoc = await getDoc(doc(db, 'users', claims.user_id));
                if (!userDoc.exists()) {
                    throw new Error('User does not exist');
                }
                setCurrentUser(userDoc.data());
            } catch (error) {
                console.error(error);
            }
        };

        if (claims.user_id) {
            getUser();
        }
    }
    , [claims.user_id]);

    const handleOnOpen = () => {
        script.current = document.createElement('script');
        script.current.src =
            'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.current.async = true;
        document.body.appendChild(script.current);
    };

    const handleOnClose = () => {
        document.body.removeChild(script.current);
    };

    return (
      <SlideOut
        id="slide-out-book-call"
        onOpen={handleOnOpen}
        onClose={handleOnClose}
      >
        <SlideOutHeader>
          <h3 className="mb-0">Book a Call</h3>
        </SlideOutHeader>
        <SlideOutContent className="pt-3">
          <div
            className="meetings-iframe-container"
            data-src={`https://meetings.hubspot.com/kolby-joyce/hire-a-freelancer?embed=true&firstName=${currentUser?.firstName || ''}&lastName=${currentUser?.lastName || ''}&email=${claims?.email || ''}&phone=${currentUser?.phone || ''}&company=${currentUser?.businessName || ''}&website=${currentUser?.businessWebsite || ''}`}
          />
        </SlideOutContent>
      </SlideOut>
    );
}

export default BookCallForm;

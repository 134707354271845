import { createContext, useContext, useReducer } from 'react';
import globalReducer from './GlobalReducer';
import { useQuery } from '../components/Hooks';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const queryURL = useQuery();

    const initialState = {
        pageTitle: '',
        backLink: '',
        currentPortfolio: null,
        currentFile: null,
        counts: {},
        filters: {
            engagements: {
                status:
                    queryURL.get('status') === 'suggested'
                        ? ['suggested']
                        : ['active', 'requested', 'suggested', 'pending'],
                archived: false,
                positions: [],
                search: '',
                manager: '',
                pending: [],
            },
            pros: {
                status: ['active'],
                positions: [],
                skills: [],
                sortby: 'availability',
                search: '',
                canBeHiredFulltime: false,
            },
        },
        engagementsView: 'table',
        userAvatar: '',
        refetch: 0,
        navNotification: {},
    };
    const [state, dispatch] = useReducer(globalReducer, initialState);

    return (
        <GlobalContext.Provider
            value={{
                ...state,
                dispatch,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = () => useContext(GlobalContext);

export default GlobalContext;

import { useEffect, useState } from 'react';
import { useGlobal } from '../../../context/GlobalContext';
import { useAuthStatus } from '../../../context/AuthContext';
import { getErrorMessage } from '../../../inc/helpers';
import { auth } from '../../../firebase.config';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { BiUser } from 'react-icons/bi';

function User() {
    const { userAvatar, dispatch } = useGlobal();
    const [showSettings, setShowSettings] = useState(false);

    const { user } = useAuthStatus();

    useEffect(() => {
        dispatch({
            type: 'SET_USER_AVATAR',
            payload: user.photoURL,
        });
    }, [user, dispatch]);

    const handleWindowClick = e => {
        if (!e.target.closest('#header-settings')) setShowSettings(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleWindowClick);

        return () => document.removeEventListener('click', handleWindowClick);
    }, []);

    const handleLogout = async () => {
        try {
            setShowSettings(false);
            await signOut(auth);
        } catch (error) {
            toast.error(getErrorMessage(error));
            console.log(error);
        }
    };

    return (
        <div
            id="header-settings"
            className="header-action | inline-flex relative"
        >
            <button
                type="button"
                className="relative inline-flex items-center justify-center h-9 w-9 rounded-full border border-gray-200 bg-white overflow-hidden hover:bg-primary-50 transition"
                onClick={() => {
                    setShowSettings(prev => !prev);
                }}
            >
                <span className="sr-only">Open user menu</span>
                {userAvatar ? (
                    <img
                        className="absolute inset-0 w-full h-full object-cover"
                        src={userAvatar}
                        alt="User avatar"
                    />
                ) : (
                    <BiUser className="fill-primary w-4 h-4" />
                )}
            </button>
            <div
                className={`absolute right-0 top-[calc(100%_+_5px)] z-10 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition-all ${
                    showSettings ? 'opacity-100 visible' : 'opacity-0 invisible'
                }`}
            >
                <div className="px-4 py-4">
                    <p className="text-sm my-0">Signed in as</p>
                    <p className="text-sm font-medium text-primary-500 truncate my-0">
                        {user?.email}
                    </p>
                </div>

                <div>
                    <Link
                        to="/settings"
                        className="flex items-center
                            px-4 py-2 gap-3 bg-white text-gray-500 font-medium hover:bg-gray-50 hover:text-gray-900"
                        onClick={() => {
                            setShowSettings(false);
                        }}
                    >
                        Settings
                    </Link>
                </div>
                <div>
                    <button
                        onClick={handleLogout}
                        className="flex items-center w-full
                            px-4 py-2 gap-3 bg-white text-red-500 font-medium hover:bg-gray-50 hover:text-gray-900
                             transition"
                    >
                        Sign out
                    </button>
                </div>
            </div>
        </div>
    );
}

export default User;

import { HiBars3 } from 'react-icons/hi2';
import { useGlobal } from '../../context/GlobalContext';
import Logo from '../../assets/images/logo-alt.png';

import Notifications from './modules/Notifications';
import User from './modules/User';

const Header = ({ setShowSidebar }) => {
    const { pageTitle } = useGlobal();

    return (
        <div className="header | flex justify-between py-3 lg:py-5 pl-1 pr-4 lg:px-6 bg-white lg:bg-gray-100 lg:border-b border-gray-300">
            <div className="header-left | flex items-center">
                <div className="header-title | hidden lg:block">
                    <h2 className="mb-0">{pageTitle}</h2>
                </div>
                <button
                    type="button"
                    className="header-nav-trigger | flex items-center justify- p-3 text-black lg:hidden"
                    onClick={() => setShowSidebar(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <HiBars3 className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="header-logo | block lg:hidden">
                    <img className="h-5 w-auto" src={Logo} alt="Logo" />
                </div>
            </div>
            <div className="header-right | flex items-center gap-4">
                <Notifications />
                <User />
            </div>
        </div>
    );
};

export default Header;

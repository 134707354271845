const globalReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PAGE_TITLE':
            return {
                ...state,
                pageTitle: action.payload,
            };
        case 'SET_COUNTS':
            return {
                ...state,
                counts: action.payload,
            };
        case 'SET_BACK_LINK':
            return {
                ...state,
                backLink: action.payload,
            };
        case 'REFETCH':
            return {
                ...state,
                refetch: state.refetch + 1,
            };
        case 'SET_ENGAGEMENTS_FILTERS':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    engagements: action.payload,
                },
            };
        case 'SET_PROS_FILTERS':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    pros: action.payload,
                },
            };
        case 'SET_ENGAGEMENTS_VIEW':
            return {
                ...state,
                engagementsView: action.payload,
            };
        case 'SET_USER_AVATAR':
            return {
                ...state,
                userAvatar: action.payload,
            };
        case 'SET_CURRENT_PORTFOLIO':
            return {
                ...state,
                currentPortfolio: action.payload,
            };
        case 'SET_CURRENT_FILE':
            return {
                ...state,
                currentFile: action.payload,
            };
        case 'SET_NAV_NOTIFICATION':
            return {
                ...state,
                navNotification: {
                    ...state.navNotification,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default globalReducer;

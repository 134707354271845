import PropTypes from 'prop-types';

function Section({ children, className = '' }) {
    return <div className={`mb-16 ${className}`}>{children}</div>;
}

Section.propTypes = {
    className: PropTypes.string,
};

export default Section;

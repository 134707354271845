import { useState, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { HiPencil, HiCheck } from 'react-icons/hi';
import { inputClasses } from '../../inc/utils';
import useQueryParams from '../../hooks/useQueryParams';
import CharsLimit from './parts/CharsLimit';

const TextInput = ({
    label,
    description = '',
    info = '',
    inputRef = null,
    capitalize = false,
    className = '',
    previewEdit = false,
    onEditComplete = null,
    syncQuery = false,
    onQuerySync = null,
    onChange = null,
    charsLimit = null,
    ...props
}) => {
    const { getQueryParam, setQueryParams } = useQueryParams();
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const [previewValue, setPreviewValue] = useState(field.value);
    const [isPreviewEditing, setIsPreviewEditing] = useState(false);

    const [prevValue, setPrevValue] = useState(field.value);
    const defaultValueSet = useRef(false);

    // Set initial value from query
    useEffect(() => {
        if (defaultValueSet.current) return;
        defaultValueSet.current = true;

        if (syncQuery === true) {
            const value = getQueryParam(props.name);
            if (value) {
                setFieldValue(props.name, value);
                if (onQuerySync) {
                    onQuerySync(value);
                }
            }
        }
    }, [
        props.name,
        field,
        syncQuery,
        setFieldValue,
        getQueryParam,
        onQuerySync,
    ]);

    // Sync query
    useEffect(() => {
        if (prevValue !== field.value) {
            setPrevValue(field.value);

            if (syncQuery === true) {
                setQueryParams({ [props.name]: field.value });
            }
        }
    }, [field.value, props.name, syncQuery, prevValue, setQueryParams]);

    // Set preview value
    useEffect(() => {
        if (previewEdit) {
            setPreviewValue(field.value);
        }
    }, [field.value, previewEdit]);

    const handlePreviewInputChange = () => {
        setIsPreviewEditing(false);

        setFieldValue(field.name, previewValue);

        onEditComplete && onEditComplete(previewValue);
    };

    // Chars limit
    useEffect(() => {
        if (field.value && charsLimit && field.value.length > charsLimit) {
            setFieldValue(field.name, field.value.slice(0, charsLimit));
        }
    }, [charsLimit, field.value, setFieldValue, field.name]);

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            {charsLimit && (
                <CharsLimit value={field.value} charsLimit={charsLimit} />
            )}
            <div
                className={
                    previewEdit && !isPreviewEditing
                        ? 'hidden'
                        : 'flex items-center space-x-2'
                }
            >
                <input
                    {...field}
                    {...props}
                    ref={inputRef}
                    className={`${inputClasses}`}
                    value={previewEdit ? previewValue : field.value}
                    onChange={e => {
                        const value = e.target.value;

                        if (previewEdit) {
                            setPreviewValue(value);
                        } else {
                            setFieldValue(field.name, value);
                        }

                        if (onChange) {
                            onChange(value);
                        }
                    }}
                    onKeyDown={e => {
                        if (previewEdit && e.code === 'Enter') {
                            e.preventDefault();
                            handlePreviewInputChange();
                        }
                    }}
                />
                {previewEdit && isPreviewEditing ? (
                    <button type="button" onClick={handlePreviewInputChange}>
                        <HiCheck className="w-4 h-4 fill-primary hover:fill-primary-500 transition" />
                    </button>
                ) : null}
            </div>
            {previewEdit && !isPreviewEditing ? (
                <div className="inline-flex items-center pl-4 py-3 space-x-2">
                    <span className="inline-block ">{field.value}</span>
                    {!props.disabled ? (
                        <button
                            type="button"
                            onClick={() => {
                                setIsPreviewEditing(true);
                            }}
                        >
                            <HiPencil className="w-4 h-4 fill-primary hover:fill-primary-500 transition" />
                        </button>
                    ) : null}
                </div>
            ) : null}

            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default TextInput;

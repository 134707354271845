import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { auth } from '../firebase.config';
import { onAuthStateChanged } from 'firebase/auth';

const AuthStatusContext = createContext();

export const AuthStatusProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [user, setUser] = useState(null);
    const [claims, setClaims] = useState(null);

    // Function to refresh user claims
    const refreshUserClaims = useCallback(async user => {
        if (user) {
            const idTokenResult = await user.getIdTokenResult(true);
            setClaims(idTokenResult.claims);

            if (idTokenResult.claims.role) {
                setIsLoggedIn(true);
            }
        }
    }, []);

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => {
            const doAsync = async () => {
                try {
                    if (user) {
                        setUser(user);
                        await refreshUserClaims(user);
                    } else {
                        setIsLoggedIn(false);
                        setUser(null);
                        setClaims(null);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setCheckingStatus(false);
                }
            };
            doAsync();
        });
        return () => {
            unsub();
        };
    }, [refreshUserClaims]);

    return (
        <AuthStatusContext.Provider
            value={{
                isLoggedIn,
                checkingStatus,
                user,
                claims,
                refreshUserClaims,
            }}
        >
            {children}
        </AuthStatusContext.Provider>
    );
};

export const useAuthStatus = () => useContext(AuthStatusContext);

export default AuthStatusContext;

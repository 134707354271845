import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => {
        const searchParams = new URLSearchParams(search);

        searchParams.set = (key, value) => {
            // console.log(searchParams.getAll());
            searchParams.delete(key);
            searchParams.append(key, value);

            window.history.pushState(
                {},
                '',
                `${window.location.pathname}?${searchParams.toString()}`
            );
        };

        return searchParams;
    }, [search]);
};

export default useQuery;

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { useState } from 'react';

const RichInput = ({
    label,
    description = '',
    info = '',
    inputRef = null,
    capitalize = false,
    className = '',
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const handleChange = (content, delta, source, editor) => {
        // Regular expression to match empty editor content
        // This matches strings that may contain <p>, <p class="">, and <br> tags
        const isEmptyContent = /^<p(\sclass="[^"]*")?>\s*<br>\s*<\/p>$/i;
    
        // Use the editor's HTML content for the check
        if(isEmptyContent.test(editor.getHTML())) {
            // Set the field value to an empty string if the content is considered empty
            setFieldValue(field.name, '');
        } else {
            // Otherwise, set the field value to the current content
            setFieldValue(field.name, content);
        }
    };

    return (
        <InputContainer>
            {label && (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            )}
            {description && <InputDescription>{description}</InputDescription>}
            <ReactQuill
                {...field}
                {...props}
                ref={inputRef}
                className={`block w-full focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-base`}
                onChange={handleChange}
                onBlur={() => {}}
                value={field.value ?? ''}
                theme="snow"
            />
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default RichInput;

import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import {
    DndContext,
    closestCorners,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { useSortable, arrayMove, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import { HiPencil } from 'react-icons/hi';
import { useModal, Modal } from '../Modals';
import { Button, ButtonSimple } from '../Buttons';

const SuperListInput = ({
    description = '',
    label,
    placeholder = '',
    className = '',
    editItem,
    ...props
}) => {
    const { setOpen } = useModal();
    const [removeItemId, setRemoveItemId] = useState(null);

    const [field, meta] = useField(props);

    const { setFieldValue } = useFormikContext();
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const [text, setText] = useState('');
    const [, setActiveId] = useState(null);

    const openRemoveItemModal = id => {
        setRemoveItemId(id);
        setOpen(`${props.id || props.name}_delete_item`);
    };

    const removeItem = id => {
        setFieldValue(
            field.name,
            field.value?.filter(item => item.id !== id)
        );
    };

    const handleAddNew = () => {
        setFieldValue(field.name, [
            ...field.value,
            {
                id: uuidv4(),
                value: text,
            },
        ]);
        setText('');
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddNew();
        }
    };

    const handleDragOver = e => {
        const { active, over } = e;

        if (over && active.id !== over.id) {
            const items = field.value;

            const oldIndex = items.findIndex(item => item.id === active.id);
            const newIndex = items.findIndex(item => item.id === over.id);

            setFieldValue(field.name, arrayMove(items, oldIndex, newIndex));
        }
    };

    const handleDragStart = e => setActiveId(e.active.id);

    const handleDragEnd = () => setActiveId(null);

    const handleUpdateValue = (...args) => {
        const id = args[0];
        const value = args[1];
        const otherArgs = args.slice(2);
        // Merge all objects in otherArgs into a single object
        const additionalProps = otherArgs.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
        );
        setFieldValue(
            field.name,
            field.value.map(item =>
                item.id === id
                    ? {
                          ...item,
                          value,
                          ...additionalProps,
                      }
                    : item
            )
        );
    };

    return (
        <DndContext
            autoScroll={false}
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragCancel={handleDragEnd}
        >
            <InputContainer className={className}>
                {label ? (
                    <InputLabel htmlFor={props.id || props.name}>
                        {label}
                    </InputLabel>
                ) : (
                    ''
                )}

                {description ? (
                    <InputDescription>{description}</InputDescription>
                ) : (
                    ''
                )}
                <div className="flex flex-col border border-solid border-light-gray pt-4 px-4 pb-2">
                    <SortableContext
                        items={field.value?.map(item => item.id)}
                        strategy={() => {}}
                    >
                        <div
                            className="relative mt-0 mx-0 mb-1 flex"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            {field?.value?.map(item => (
                                <SuperListItem
                                    key={item.id}
                                    id={item.id}
                                    value={item.value}
                                    openRemoveItemModal={openRemoveItemModal}
                                    editItem={id =>
                                        editItem({
                                            id,
                                            ...item,
                                            value: item.value,
                                            updateValue: handleUpdateValue.bind(
                                                null,
                                                id
                                            ),
                                        })
                                    }
                                    extraProps={{ ...props, ...item }}
                                />
                            ))}
                        </div>
                    </SortableContext>
                    <input
                        type="text"
                        className="p-0 border-0 outline-none focus:ring-0"
                        placeholder={placeholder}
                        value={text}
                        onChange={e => setText(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {meta.touched && meta.error ? (
                    <InputError>{meta.error}</InputError>
                ) : null}
            </InputContainer>
            <Modal id={`${props.id || props.name}_delete_item`}>
                <h2 className="mb-8">Are you sure?</h2>
                <div className="flex">
                    <Button
                        className="mr-8"
                        type="button"
                        onClick={() => {
                            removeItem(removeItemId);
                            setOpen('');
                        }}
                    >
                        Yes, delete
                    </Button>
                    <ButtonSimple type="button" onClick={() => setOpen('')}>
                        No, cancel
                    </ButtonSimple>
                </div>
            </Modal>
        </DndContext>
    );
};

export default SuperListInput;

function SuperListItem(props) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString({
            ...transform,
            scaleY: 1,
            scaleX: 1,
        }),
        transition,
    };

    console.log(props);
    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className="inline-flex relative pt-0 pr-3 pb-3 pl-0"
        >
            <span
                {...listeners}
                className="inline-flex items-center text-white bg-primary-600 py-1 pl-4 pr-2 text-sm cursor-grab rounded-tl-full rounded-bl-full"
            >
                {props.value}
                {props.extraProps[props.extraProps.modifier] && (
                    <span className="ml-1 text-yellow-400">
                        {props.extraProps.modifierIcon}
                    </span>
                )}
            </span>
            <button
                type="button"
                className="rounded-none py-1 px-2 text-white bg-primary-700 hover:bg-primary-900 cursor-pointer transition"
                onClick={() => props.editItem(props.id)}
            >
                <HiPencil fill="white" />
            </button>
            <button
                type="button"
                onClick={() => props.openRemoveItemModal(props.id)}
                className="rounded-none py-1 pl-2 pr-3 text-white bg-primary-800 hover:bg-red-700 cursor-pointer rounded-tr-full rounded-br-full transition"
            >
                &times;
            </button>
        </div>
    );
}

import { ReactComponent as IconDeveloper } from '../assets/svg/developer.svg';
import { ReactComponent as IconDesigner } from '../assets/svg/designer.svg';
import { ReactComponent as IconCopywriter } from '../assets/svg/copywriter.svg';
import { ReactComponent as IconAssistant } from '../assets/svg/virtual_assistant.svg';

import PDFIcon from '../assets/images/pdf.png';
import DOCIcon from '../assets/images/doc.png';
import XLSIcon from '../assets/images/xls.png';
import PPTIcon from '../assets/images/ppt.png';
import ZIPIcon from '../assets/images/zip.png';
import TXTIcon from '../assets/images/txt.png';
import PNGIcon from '../assets/images/png.png';
import MP4Icon from '../assets/images/mp4.png';
import MP3Icon from '../assets/images/mp3.png';
import JPGIcon from '../assets/images/jpg.png';
import AVIIcon from '../assets/images/avi.png';
import CSVIcon from '../assets/images/csv.png';
import RARIcon from '../assets/images/rar.png';
import DefaultDocumentIcon from '../assets/images/document.png';

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const scrollbarClasses =
    'scrollbar-thin scrollbar-thumb-light-gray scrollbar-track-lighter-gray scrollbar-thumb-rounded';

export const scrollbarClassesDark =
    'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded';

export const inputClasses =
    'block w-full px-3 py-2 rounded-lg border bg-white border-gray-300 focus:outline-none focus:border-primary-700 text-base text-gray-900 disabled:opacity-50 disabled:text-gray-500 disabled:bg-gray-200 disabled:cursor-not-allowed';

export const getStatusBgColorClass = status => {
    if (status === 'active') return 'bg-light-green';
    if (status === 'suggested') return 'bg-light-blue';
    if (status === 'requested') return 'bg-light-yellow';
    if (status === 'inactive') return 'bg-light-red';
    if (status === 'pending') return 'bg-gray-200';
    return 'bg-lighter-gray';
};

export const getStatusBgColorDark = status => {
    if (status === 'active') return 'bg-green-600';
    if (status === 'inactive') return 'bg-red-600';
    if (status === 'pending') return 'bg-yellow-600';
    return 'bg-gray-400';
};

export const getStatusTextColorClass = status => {
    if (status === 'active') return 'text-green-800';
    if (status === 'suggested') return 'text-blue-800';
    if (status === 'requested') return 'text-yellow-700';
    if (status === 'inactive') return 'text-red-700';
    if (status === 'pending') return 'text-gray-900';
    if (status === 'archived') return 'text-red-800';
    return 'text-gray-600';
};

export const getProStatusBgColorClass = status => {
    if (status === 'active') return 'bg-light-green';
    if (status === 'pending') return 'bg-light-yellow';
    if (status === 'inactive') return 'bg-light-red';
    return 'bg-lighter-gray';
};

export const getPositionIcon = ({ position, className = '' }) => {
    if (position === 'position_developer')
        return <IconDeveloper className={className} />;
    if (position === 'position_designer')
        return <IconDesigner className={className} />;
    if (position === 'position_copywriter')
        return <IconCopywriter className={className} />;
    if (position === 'position_virtual_assistant')
        return <IconAssistant className={className} />;
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeWords = sentence => {
    const words = sentence.toLowerCase().split(' ');

    const capitalizedWords = words.map((word, index) => {
        if (word === 'a' || word === 'the') {
            return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return capitalizedWords.join(' ');
};

export const formatStatus = status =>
    capitalize(String(status).replace('_', ' '));

export const getFirebaseConfigForPublic = () => {
    if (process.env.NODE_ENV === 'production') {
        return {
            apiKey: 'AIzaSyAkWx04OgqXwm9DmdHsJrjurmcJWIY6aCo',
            authDomain: 'quickly-hire-app-production.firebaseapp.com',
            projectId: 'quickly-hire-app-production',
            storageBucket: 'quickly-hire-app-production.appspot.com',
            messagingSenderId: '1080164867138',
            appId: '1:1080164867138:web:2408705cc2b6c9bc4ea1db',
        };
    } else if (process.env.NODE_ENV === 'staging') {
        return {
            apiKey: 'AIzaSyAORDwK_vzShmaTxsofFhn-at5_SDrSC_c',
            authDomain: 'quickly-hire-app.firebaseapp.com',
            projectId: 'quickly-hire-app',
            storageBucket: 'quickly-hire-app.appspot.com',
            messagingSenderId: '80093406402',
            appId: '1:80093406402:web:4fd04bf9a676a460f0875b',
            measurementId: 'G-Y86860DQ5N',
        };
    } else
        return {
            apiKey: 'AIzaSyCJY0JwKQD7o4K_uch0k4KALTEaGBlkWC4',
            authDomain: 'quickly-hire-app-development.firebaseapp.com',
            projectId: 'quickly-hire-app-development',
            storageBucket: 'quickly-hire-app-development.appspot.com',
            messagingSenderId: '710665664799',
            appId: '1:710665664799:web:f31e11f20a2130c89a582a',
        };
};

export const firestoreAutoId = () => {
    const CHARS =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let autoId = '';

    for (let i = 0; i < 28; i++) {
        autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
    }
    return autoId;
};

export const getFileTypeIcon = fileType => {
    if (!fileType) return DefaultDocumentIcon;

    const fileFormat = fileType.split('/')[1];

    switch (fileFormat) {
        case 'pdf':
            return PDFIcon;
        case 'doc':
        case 'docx':
            return DOCIcon;
        case 'xls':
        case 'xlsx':
            return XLSIcon;
        case 'ppt':
        case 'pptx':
            return PPTIcon;
        case 'zip':
            return ZIPIcon;
        case 'txt':
            return TXTIcon;
        case 'png':
            return PNGIcon;
        case 'mp4':
            return MP4Icon;
        case 'mp3':
            return MP3Icon;
        case 'jpg':
        case 'jpeg':
            return JPGIcon;
        case 'avi':
            return AVIIcon;
        case 'csv':
            return CSVIcon;
        case 'rar':
            return RARIcon;
        default:
            return DefaultDocumentIcon;
    }
};

function GroupLabel({ children, className, ...props }) {
    return (
        <div
            className={`block text-base leading-6 font-medium text-black mb-6 ${className}`}
            {...props}
        >
            {children}
        </div>
    );
}

export default GroupLabel;

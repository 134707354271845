import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthStatus } from "../../context/AuthContext";

const HubSpotTracker = () => {
  const location = useLocation();
  const { claims } = useAuthStatus();

  useEffect(() => {
   if ( claims?.role === "client" ) {
     var _hsq = (window._hsq = window._hsq || []);
     if (_hsq) {
       _hsq.push([
         "setPath",
         location.pathname + location.search + location.hash,
       ]);
       _hsq.push(["trackPageView"]);
     }
   }
  }, [location, claims]);

  return null;
};

export default HubSpotTracker;

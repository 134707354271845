import React, { useState, useEffect } from 'react';
import { regionsAndCountries } from '../../inc/data';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { useField } from 'formik';
import { inputClasses } from '../../inc/utils';

const RegionAndCountrySelector = ({
    label,
    description = '',
    info = '',
    className = '',
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <InputContainer>
            <InputLabel htmlFor={props.id || props.name}>
                Region and country
            </InputLabel>
            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <select
                {...props}
                {...field}
                className={`${inputClasses} ${className}`}
            >
                <option value="">Select a region and country</option>
                {regionsAndCountries?.map(region => (
                    <optgroup key={region.regionCode} label={region.regionName}>
                        {region.countries.map(country => (
                            <option
                                key={country.code}
                                value={country.code}
                            >
                                {country.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default RegionAndCountrySelector;

import { createContext, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthStatus } from './AuthContext';

const GAContext = createContext();

export const GAProvider = ({ children }) => {
    const location = useLocation();
    const { isLoggedIn, claims } = useAuthStatus();

    useEffect(() => {
        const pageData = {
            page_path: location.pathname + location.search + location.hash,
            page_title: location.pathname,
            page_search: location.search,
            page_hash: location.hash,
        };

        const userData = {
            role: isLoggedIn ? claims?.role : 'loggedOut',
        };

        window.gtag('event', 'page_view', pageData);
        window.gtag('set', 'user_properties', userData);

        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
            'setPath',
            location.pathname + location.search + location.hash,
        ]);
        _hsq.push(['trackPageView']);
    }, [location, isLoggedIn, claims?.role]);

    return <GAContext.Provider value={{}}>{children}</GAContext.Provider>;
};

export const useGAStatus = () => useContext(GAContext);

export default GAContext;

import { HiArrowRight } from 'react-icons/hi';
import { useSlideOut } from '../../../core-ui/SlideOut/SlideOut';
function BookCallWidget() {
    const { openSlide } = useSlideOut();

    return (
        <div className="bg-black-alt text-white px-4 py-4 rounded-3xl lg:mt-auto">
            <div className="text-lg font-semibold mb-1">Need Help?</div>
            <div className="text-sm mb-3">
                Let us match you with the perfect freelancer!
            </div>
            <button
                className="bg-primary-lime text-black-alt flex w-full justify-center items-center gap-2 py-2 px-4 rounded-full text-base font-medium hover:bg-primary-500 transition-colors"
                onClick={() => {
                    openSlide('slide-out-book-call');
                }}
            >
                <span>Talk to an Advisor</span>
                <HiArrowRight />
            </button>
        </div>
    );
}

export default BookCallWidget;

import { Link } from 'react-router-dom';

function ButtonOutline({ children, className = '', to, href, ...props }) {
    const classNameStr = `inline-flex justify-center items-center gap-2 text-base bg-white px-4 py-2 rounded-lg border border-primary-600 text-gray-500 font-medium text-base whitespace-nowrap focus:outline-none hover:bg-primary-50 disabled:border-primary-400 disabled:bg-gray-200 disabled:text-gray-300 transition ${className}`;

    if (href) {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={classNameStr}
            >
                {children}
            </a>
        );
    }

    if (to)
        return (
            <Link to={to} className={classNameStr} {...props}>
                {children}
            </Link>
        );

    return (
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default ButtonOutline;

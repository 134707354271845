import { Link } from 'react-router-dom';

function Button({ children, className = '', to = '', ...props }) {
    const classNameStr = `inline-flex justify-center items-center gap-1 text-base bg-primary-700 px-4 py-2 rounded-lg border border-primary-600 text-white font-medium text-base whitespace-nowrap focus:outline-none hover:bg-primary-800 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-300 transition ${className}`;

    return to ? (
        <Link to={to} className={classNameStr} {...props}>
            {children}
        </Link>
    ) : (
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default Button;

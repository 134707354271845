import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { useAuthStatus } from '../../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db, functions } from '../../firebase.config';
import { httpsCallable } from 'firebase/functions';

const IntercomWrapper = ({ children }) => {
    const location = useLocation();
    const { claims, isLoggedIn } = useAuthStatus();
    const { user_id: userId, email, name, role } = claims || {};
    const [engagements, setEngagements] = useState(null);
    const [user, setUser] = useState(null);

    const outlet = useOutlet();

    const getEngagements = useCallback(
        async userType => {
            if (['admin', 'sales', 'recruiter', 'manager'].includes(role))
                return;
            try {
                const getEngagementsFunction = httpsCallable(
                    functions,
                    userType === 'client'
                        ? 'getClientEngagements_g2'
                        : 'getProEngagements_g2'
                );

                const { data: response } = await getEngagementsFunction({
                    userId,
                });
                const activeEngagements = response?.engagements?.filter(
                    engagement => engagement.status === 'active'
                );
                const previousEngagements = response?.engagements?.filter(
                    engagement => engagement.status === 'cancelled'
                );
                setEngagements({ active: activeEngagements, previous: previousEngagements }  || []);
            } catch (error) {
                console.error(
                    `Error fetching ${userType}'s engagements:`,
                    error
                );
            }
        },
        [role, userId]
    );

  const getUser = useCallback(async () => {
    try {
      const userSnap = await getDoc(doc(db, "users", userId));
      if (!userSnap.exists()) throw new Error("Wrong user id");
      setUser(userSnap.data() || null);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [userId]);

  useEffect(() => {
    if (isLoggedIn) {
      getEngagements(role);
      getUser();
    }
  }, [getEngagements, getUser, isLoggedIn, role]);

  const initializeIntercom = useCallback(async () => {
    if (!window.Intercom || !user || !isLoggedIn || !["client", "pro"].includes(role)) {
      window.Intercom?.("shutdown");
      return;
    }

    try {
      window.Intercom("shutdown");
      const metadata = {
        api_base: process.env.REACT_APP_INTERCOM_API_BASE_URL,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name,
        email,
        user_id: user?.intercomId,
        Role: role,
        Status: user?.status || "N/A",
        "Active Engagements": engagements.active ? engagements.active.length : null,
        "Previous Engagements": engagements.previous ? engagements.previous.length : 0,
        // Additional metadata based on role
        ...(role === "client" && {
          "Company Name": user?.businessName || "N/A",
          "Company Website": user?.businessWebsite || "N/A",
          phone: user?.phone || "N/A",
          "Referred to QH by": user?.referredBy || "N/A",
          "PS Partner Key": user?.psPartnerKey || "N/A",
          "Signup Method": user?.signupMethod || "N/A",
          "Payment Platform": user?.stripeCustomerId ? "Stripe" : "WHMCS",
        }),
        ...(role === "pro" && {
          "Payment Platform": user?.paymentPlatform || "N/A",
          "Position": user?.positionTitle || "N/A", 
          "Available Hours": user?.availableHours || "N/A", 
          // other pro-specific metadata...
        }),
      };
      window.Intercom("boot", metadata);
      window.Intercom("update");
    } catch (error) {
      console.error("Error initializing Intercom:");
    }
  }, [role, isLoggedIn, name, email, engagements, user]);

    useEffect(() => {
        initializeIntercom();
    }, [initializeIntercom, location.pathname]);

    useEffect(() => {
        const handleNewMessage = () => {
            window.Intercom('update');
        };

        window.Intercom?.('onNewMessage', handleNewMessage);

        return () => {
            window.Intercom?.('offNewMessage', handleNewMessage);
        };
    }, [location.pathname]);

    return <>{outlet}</>;
};

export default IntercomWrapper;

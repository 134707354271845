function InputLabel({ children, ...props }) {
    return (
        <label
            className={`block font-semibold text-sm text-gray-900 mb-2 whitespace-pre-line ${
                props.uppercase && 'uppercase'
            }`}
            {...props}
        >
            {children}
        </label>
    );
}

export default InputLabel;

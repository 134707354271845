import { Link } from 'react-router-dom';

function ButtonSimple({
    children,
    small = false,
    className = '',

    to = '',
    href = '',
    ...props
}) {
    const classNameStr = `inline-flex justify-center items-center gap-2 outline-none ring-0 no-underline font-semibold text-base whitespace-nowrap leading-3 text-primary-500 hover:text-primary-600 focus:text-primary-600 transition-colors ${className}`;

    return to ? (
        <Link to={to} className={classNameStr} {...props}>
            {children}
        </Link>
    ) : href ? (
        <a href={href} className={classNameStr} {...props}>
            {children}
        </a>
    ) : (
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default ButtonSimple;
